nav {
    padding-top: 20px;
    color: snow;
}

.sidebar .nav-link {
    color: gray;
    font-weight: 500;
}

.sidebar .nav-link.active {
    color: snow;
}