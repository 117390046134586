.resource-group-text {
    background-color: inherit;
    border: inherit;
    border-radius: inherit;
    width: 3rem;
    text-align: right;
}

.resource-form-control {
    margin-top: 0.5rem;
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
}